import createQueryHook from '@segment/utils/createQueryHook';
import { useStaticSiteBackend } from '@segment/contexts/staticSiteBackend';
import { fetchCdpWeekSpeakerLandingPage } from '../../../staticSiteBackend/cdpWeek2023';
export var createCdpWeekSpeakerLandingPageQuery = function createCdpWeekSpeakerLandingPageQuery(client, _ref) {
  var speakersLimit = _ref.speakersLimit;
  return [["cdpWeek2023", "LandingPage"], function () {
    return fetchCdpWeekSpeakerLandingPage(client, speakersLimit);
  }];
};
export var useCdpWeekSpeakerLandingPageQuery = createQueryHook(createCdpWeekSpeakerLandingPageQuery, useStaticSiteBackend, {
  staleTime: Infinity
});
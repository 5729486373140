import createQueryHook from '@segment/utils/createQueryHook';
import { useStaticSiteBackend } from '@segment/contexts/staticSiteBackend';
import { fetchCdpWeekAgendaPage } from '../../../staticSiteBackend/cdpWeek2023';
export var createCdpWeekAgendaPageQuery = function createCdpWeekAgendaPageQuery(client) {
  return [["cdpWeek2023", "AgendaPage"], function () {
    return fetchCdpWeekAgendaPage(client);
  }];
};
export var useAgendaQuery = createQueryHook(createCdpWeekAgendaPageQuery, useStaticSiteBackend, {
  staleTime: Infinity
});
import { useEffect, useState } from 'react'

const useDomainPath = (url = ``) => {
  const [path, setPath] = useState(``)

  useEffect(() => {
    const knownDomains = /^(http:\/\/|https:\/\/)?([a-zA-Z0-9]+.)?segment.(build|com)(\/.*)?$/i
    let cleanPath = url ? `/${url}` : ``

    if (location.host.startsWith(`localhost`) || knownDomains.test(location.host)) {
      cleanPath = `/cdp-week-2023${cleanPath}/`
    }

    setPath(cleanPath)
  }, [])

  return path
}

export default useDomainPath
